<template>
	<div class="home">
		<!-- <span class="title">杭州蓝线网络科技有限公司</span>
		<span style="font-size: 0.24rem;margin-top: 0.2rem;">专为用户打造便捷的生活APP工具 </span> -->
		<img style="width: 80%;" src="../static/image/content.png"/>
		<span class="title">联系我们</span>
		<span style="font-size: 0.24rem;margin-top: 0.2rem;">期待跟您一起合作！ </span>
		<div id="container"></div> 
		
		<span style="font-size: 0.18rem;margin-top: 0.6rem;">©杭州蓝线网络科技有限公司</span>
		<a style="font-size: 0.18rem;" href="https://beian.miit.gov.cn/" target="_blank">浙ICP备2022006974号</a>
	</div>
	
</template>

<script>
	// @ is an alias to /src

	export default {
		name: 'Home',
		components: {},
		data() {
			return {
			}
		},
		created() {
			
		},
		
		mounted() {
			var map = new BMapGL.Map("container");
			var point = new BMapGL.Point(120.03798,30.24999);
			map.centerAndZoom(point, 15);
			map.enableScrollWheelZoom(true); 
		}
	}
</script>
<style scoped>
	.home {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding-bottom: 0.8rem;
	}
	.title {
		font-size: 0.38rem;
		margin-top: 1rem;
		font-weight: bold;
	}
	#container {
		width: 6rem;
		height: 3rem;
		margin-top: 0.2rem;
	}

</style> 
