<template>
	<div class="head">
		<div style="display: flex;align-items: center;">
			<!-- <img src="../static/image/329611_92205.webp" />
			<strong style="font-size: 0.32rem;margin-left: 0.25rem;">一切为了用户</strong> -->
		</div>
		<div class="head-right">
			<span :style="{color:selectedTab=='首页'?'#ff0000':''}" @click="chooseTab('首页')">首页</span>
			<div class="ys"  @mouseenter="ysOver">
				<span :style="{color:selectedTab=='隐私政策'?'#ff0000':''}">隐私政策</span>
				<div v-if="showYs" class="ys-layout"  @mouseleave="ysOut">
					<span @click="chooseTab('手机清灰管家')">手机清灰管家</span>
					<span @click="chooseTab('手机换机互传助手')">手机换机互传助手</span>
					<span @click="chooseTab('Moon心情日记')">Moon心情日记</span>
					<span @click="chooseTab('茄子手机搬家')">茄子手机搬家</span>
				</div>
			</div>
			<span :style="{color:selectedTab=='用户协议'?'#ff0000':''}" @click="chooseTab('用户协议')">用户协议</span>
			
		</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	export default {
		name: 'head',
		components: {},
		data() {
			return {
				selectedTab: "首页",
				showYs:false,
			}
		},
		methods:{
			chooseTab(item) {
				this.selectedTab=item
				if(this.selectedTab=="手机清灰管家"){
					window.location.href="/shoujiqinghui"
				}
				if(this.selectedTab=="手机换机互传助手"){
					window.location.href="/shoujihuanji"
				}
				if(this.selectedTab=="Moon心情日记"){
					window.location.href="/moonriji"
				}
				if(this.selectedTab=="茄子手机搬家"){
					window.location.href="/qiezibanjia"
				}
				if(this.selectedTab=="用户协议"){
					window.location.href="/agreement"
				}
			},
			ysOver() {
				console.log("over")
				this.showYs = true;
			},
			ysOut() {
				console.log("out")
				this.showYs = false;
			}
		}
	}
</script>
<style scoped>
	.head {
		position: absolute;
		top: 0;
		left: 0;
		background: #FFFFFF;
		
		width: 100%;
		padding: 0 3.2rem;
		box-sizing: border-box;
		justify-content: space-between;
		height: 1rem;
		display: flex;
		align-items: center;

	}

	.head img {
		width: 0.5rem;
		height: 0.65rem;
	}

	.head-right {
		display: flex;
		align-items: center;
		position: relative;
	}

	.head-right > span {
		font-size: 0.22rem;
		font-weight: bold;
		margin-left: 0.15rem;
		cursor: pointer;
	}
	
	.head-right > span:hover {
		color: red;
	}
	.ys {
		display: flex;
		position: relative;
	}
	.ys > span {
		font-size: 0.22rem;
		font-weight: bold;
		margin-left: 0.15rem;
		cursor: pointer;
	}
	
	.ys-layout {
		position: absolute;
		top: 0.4rem;
		left: 0.15rem;
		width: 2rem;
		max-height: 3rem;
		box-shadow: 0px 0px 3px 3px #dcdcdc;
		background: #FFFFFF;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding: 0.1rem 0.2rem;
		box-sizing: border-box;
	}
	.ys-layout > span {
		font-size: 0.18rem;
		cursor: pointer;
		line-height: 0.4rem;
	}
	.ys-layout > span:hover {
		color: red;
	}
</style>
