<template>
	<my-head></my-head>
	<router-view style="margin-top: 1rem;"/>
</template>
<script>
	// @ is an alias to /src
	import myHead from './components/head.vue'
	export default {
		components: {myHead}
	}
</script>
<style>
	#app {
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-align: center;
		color: #2c3e50;
	}

	#nav {
		padding: 30px;
	}

	#nav a {
		font-weight: bold;
		color: #2c3e50;
	}

	#nav a.router-link-exact-active {
		color: #42b983;
	}
</style>
